import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../layouts/Layout';

function Section({ title, html }: { title: string; html: string }) {
  return (
    <div>
      <h3 className="text-2xl text-blue mb-3">{title}</h3>
      <div className="markdown" dangerouslySetInnerHTML={{ __html: html }}></div>
    </div>
  );
}

export default function FindUsPage({ data }: { data: any }) {
  const { address, openingHours, contactUs } = data;

  return (
    <Layout>
      <title>Vicarage Road Chippy | Morriston, Swansea | Find Us</title>
      <div className="grid gap-5 grid-cols-1 md:grid-cols-3">
        <Section title="Vicarage Road Chippy" html={address.html} />
        <Section title="Opening Hours" html={openingHours.html} />
        <Section title="Contact Us" html={contactUs.html} />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    address: markdownRemark(fileAbsolutePath: { regex: "/address.md/" }) {
      html
    }
    openingHours: markdownRemark(fileAbsolutePath: { regex: "/opening-hours.md/" }) {
      html
    }
    contactUs: markdownRemark(fileAbsolutePath: { regex: "/contact-us.md/" }) {
      html
    }
  }
`;
